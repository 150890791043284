// extracted by mini-css-extract-plugin
export var button = "sign-in-page-module--button--3e98e";
export var ccsDiv = "sign-in-page-module--ccsDiv--a28ae";
export var contactDetailsIcon = "sign-in-page-module--contactDetailsIcon--8a370";
export var contactFormDiv = "sign-in-page-module--contactFormDiv--39ca1";
export var contactFormInputsDiv = "sign-in-page-module--contactFormInputsDiv--0cd7e";
export var displayDiv = "sign-in-page-module--displayDiv--7ddb6";
export var emailDiv = "sign-in-page-module--emailDiv--85f08";
export var footerContentDiv = "sign-in-page-module--footerContentDiv--b2190";
export var footerDiv = "sign-in-page-module--footerDiv--c1c8d";
export var footerLDiv = "sign-in-page-module--footerLDiv--5e67c";
export var footerLinksDiv = "sign-in-page-module--footerLinksDiv--01cc1";
export var footerLogoDiv = "sign-in-page-module--footerLogoDiv--309be";
export var footerRDiv = "sign-in-page-module--footerRDiv--0f552";
export var formTitleDiv = "sign-in-page-module--formTitleDiv--4698c";
export var globalCareSupply = "sign-in-page-module--globalCareSupply--bab81";
export var ifYouAreNotRegisteredGo = "sign-in-page-module--ifYouAreNotRegisteredGo--cfe90";
export var input = "sign-in-page-module--input--cf559";
export var inputFieldWIthLabelDiv = "sign-in-page-module--inputFieldWIthLabelDiv--dd6fb";
export var inputLabelDiv = "sign-in-page-module--inputLabelDiv--a5dde";
export var inputLabelDiv2 = "sign-in-page-module--inputLabelDiv2--6f97d";
export var inputRowDiv = "sign-in-page-module--inputRowDiv--3af51";
export var logoA = "sign-in-page-module--logoA--b9bed";
export var privacyPolicy = "sign-in-page-module--privacyPolicy--908f3";
export var registerContentDiv = "sign-in-page-module--registerContentDiv--b12df";
export var registerPageB = "sign-in-page-module--registerPageB--190a1";
export var registerSectionDiv = "sign-in-page-module--registerSectionDiv--debb3";
export var signInPageDiv = "sign-in-page-module--signInPageDiv--bc833";
export var udshSymbol1Icon = "sign-in-page-module--udshSymbol1Icon--4b722";
export var visaIcon = "sign-in-page-module--visaIcon--ea274";